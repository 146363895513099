import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import BlogDetails from './blog-components/blog-details';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { useParams } from 'react-router-dom';
import { articleList } from '../helper/staticDataList';

const BlogDetailsPage = () => {
    const { id } = useParams();
    const [blogDetail, setBlogDetail] = React.useState();
    function findArrayElementByTitle(array, title) {
        return array.find((element) => {
          return element.id === title;
        })
    }
    React.useEffect(() => {
        
        setBlogDetail(findArrayElementByTitle(articleList, id))
    }, [])
    return <div>
        <Navbar />
        {/* <PageHeader headertitle="News Details" /> */}
        <hr></hr>
        <BlogDetails blogDetail={blogDetail?blogDetail:{}} />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default BlogDetailsPage

