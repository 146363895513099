import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Social extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__social-media">
			<ul>
				<li><a href="https://www.facebook.com/emlakkilavuzu/?hc_ref=ARSl3nI4vn6XcaHYc4xl6P0Rfg1vFO_kQOMa_BikB26Ico1U3_55sOrrVzpl_LCn674&fref=nf&__xts__[0]=68.ARA5H46bYpV6ux2gh4NWiNgfgZ1uRJNRF5qnSMBAZSLwlCO79Yo7hjby5wMdCK5Pkvn48v05pTnKlrMSz8Aka2A4mXQGzArU0SJrlldL1fPFIk8_8NycVh5ab-vptv5OvoyPuW2kGR2B0q_XF-g6xrVY4NxFoCVEdB2O-0Vy7ONCdOaxt_Ur4oBVoJseM-xiXkxuxILgjcMVpylYCboJ1uoeJRrfLvEXX3n7NiVEPnVFxYZAZLpPdOkIXAVMOkRef6gmXGIyF_VUeLtVrKe3iz84zhIrZPBWR7fZTT73_vsLDSv6fVM" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
				{/* <li><a href="https://twitter.com/brokermurat" title="Twitter"><i className="fab fa-twitter" /></a></li> */}
				<li><a href="https://www.instagram.com/emlakkilavuzu/" title="Instagram"><i className="fab fa-instagram" /></a></li>
				<li><a href="https://emlakkilavuzu.sahibinden.com/" title="Dribbble"><i className="fab fa-dribbble" /></a></li>
				<li><a href="https://www.zingat.com/emlak-kilavuzu-59042g" title="Dribbble"><img src={publicUrl+"assets/zingat.png"} color='white' width={20	} height={19}></img></a></li>
				<li><a href="https://www.linkedin.com/company/emlak-kilavuzu/?originalSubdomain=tr" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
				<li><a href="https://www.youtube.com/@EmlakKilavuzu" title="Youtube"><i className="fab fa-youtube" /></a></li>
				
			</ul>
		</div>
        }
}

export default Social