import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class BannerV3 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return  <div className="ltn__slider-area ltn__slider-3  section-bg-2">
			  <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1">
			    {/* ltn__slide-item */}
			    <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-60" data-bs-bg={"https://r.resimlink.com/VxZXhLnKFe5.jpg"}>
			      <div className="ltn__slide-item-inner text-center">
			        <div className="container">
			          <div className="row">
			            <div className="col-lg-12 align-self-center">
			              <div className="slide-item-info">
			                <div className="slide-item-info-inner ltn__slide-animation">
			                  <div className="slide-video mb-50 d-none">
			                    <a className="ltn__video-icon-2 ltn__video-icon-2-border" href="https://www.youtube.com/embed/tlThdr3O5Qo" data-rel="lightcase:myCollection">
			                      <i className="fa fa-play" />
			                    </a>
			                  </div>
			                  
			                  <h1 className="slide-title animated ">Semtinizin Emlak Rehberi</h1>
			                  <div className="slide-brief animated">
			                    <p>Emin adımlarla ilerleyen EMLAK KILAVUZU, SEMTİNİZİN EMLAK REHBERİ sloganıyla, kazançlı ve doğru çözümler ile kaliteli hizmeti profesyonelce sunar.</p>
			                  </div>
			                  <div className="btn-wrapper animated go-top">
			                  
			                  </div>
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			    {/* ltn__slide-item */}
			    <div className="ltn__slide-item ltn__slide-item-2 P ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-60" data-bs-bg={"https://wallpaperfx.com/view_image/luxury-house-2560x1600-wallpaper-7059.jpg"}> 
			      <div className="ltn__slide-item-inner  text-center text-end">
			        <div className="container">
			          <div className="row">
			            <div className="col-lg-12 align-self-center">
			              <div className="slide-item-info">
			                <div className="slide-item-info-inner ltn__slide-animation">
			                  
							<h1 className="slide-title animated ">Emlak Kılavuzu<br></br>Olmak İster Misin?<br /></h1>
			                  <div className="slide-brief animated">
			                    <p>Sektörün En Dinamik ve En Yüksek Kazanç Modeli Sunan Kurumsal Ofisinde Siz De Yerinizi Alın.</p>
			                  </div>
			                  <div className="btn-wrapper animated go-top">
			                    
			                  </div>
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			    {/* ltn__slide-item */}
			    
			    {/*  */}
			  </div>
			</div>

        }
}

export default BannerV3