import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { articleList } from '../../helper/staticDataList';
import Comments from './comments';
import Sidebar from './sidebar';
class BlogDetails extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'
	  const blogDetail = this.props?.blogDetail
	  console.log(blogDetail);
	  
    return (
		<div className="ltn__page-details-area ltn__blog-details-area mb-120">
			<div className="container">
			<div className="row">
				<div className="col-lg-8">
				<div className="ltn__blog-details-wrap">
					<div className="ltn__page-details-inner ltn__blog-details-inner">
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-category">
											<Link to="/shop">{blogDetail?.tag?.[0]}</Link>
						</li>
						</ul>
					</div>
					<h2 className="ltn__blog-title">{blogDetail.title}
					</h2>
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-author go-top">
											<Link to="/team-details">Yazar: {blogDetail.writerBy}</Link>
						</li>
						<li className="ltn__blog-date">
							<i className="far fa-calendar-alt" />{blogDetail.date}
						</li>
						<li>
							
						</li>
						</ul>
					</div>
							
			
							</div>
							<img src={blogDetail.image} alt="Image" />
							{/* blog-tags-social-media */}
							<br/><br/>
							{blogDetail?.descList?.map((row) => {
								return (
									<>
									<h4>{row.title}</h4>
									<p className='pDetail'>{row.subTitle}</p>
									</>

								)
							})}
					
					<div className="ltn__blog-tags-social-media mt-80 row">
					<div className="ltn__tagcloud-widget col-lg-8 go-top">
						<h4>İlgili Başlıklar</h4>
									<ul>
										{blogDetail.tag?.map((row) => {
											return (
												<li>
												<Link to="/shop">{row}</Link>
											</li>
											)
										})}
				
				
						</ul>
								</div>
								
					{/* <div className="ltn__social-media text-right text-end col-lg-4">
						<h4>Social Share</h4>
						<ul>
						<li><a href="#" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
						<li><a href="#" title="Twitter"><i className="fab fa-twitter" /></a></li>
						<li><a href="#" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
						<li><a href="#" title="Youtube"><i className="fab fa-youtube" /></a></li>
						</ul>
					</div> */}
					</div>
					<hr />
					{/* prev-next-btn */}
				
					<hr />
					{/* related-post */}
					<div className="related-post-area mb-50">
					<h4 className="title-2">Related Post</h4>
					<div className="row">
						<div className="col-md-6">
						{/* Blog Item */}
						<div className="ltn__blog-item ltn__blog-item-6">
							<div className="ltn__blog-img">
							<Link to={`/blog-details/${articleList[1].id}`}><img src={articleList[1].image} alt="Image" /></Link>
							</div>
							<div className="ltn__blog-brief">
							<div className="ltn__blog-meta">
								<ul>
								<li className="ltn__blog-date ltn__secondary-color">
									<i className="far fa-calendar-alt" />Şubat 16, 2023
								</li>
								</ul>
							</div>
							<h3 className="ltn__blog-title"><Link to={`/blog-details/${articleList[1].id}`}>{articleList[1].title}</Link></h3>
							<p>Lorem ipsum dolor sit amet, conse ctet ur adipisicing elit, sed doing.</p>
							</div>
						</div>
						</div>
						<div className="col-md-6">
						{/* Blog Item */}
						<div className="ltn__blog-item ltn__blog-item-6">
							<div className="ltn__blog-img">
							<Link to={`/blog-details/${articleList[2].id}`}><img src={articleList[2].image} alt="Image" /></Link>
							</div>
							<div className="ltn__blog-brief">
							<div className="ltn__blog-meta">
								<ul>
								<li className="ltn__blog-date ltn__secondary-color">
									<i className="far fa-calendar-alt" />Şubat 14, 2023
								</li>
								</ul>
							</div>
							<h3 className="ltn__blog-title"><Link to={`/blog-details/${articleList[2].id}`}>{articleList[2].title}</Link></h3>
							<p>Lorem ipsum dolor sit amet, conse ctet ur adipisicing elit, sed doing.</p>
							</div>
						</div>
						</div>
					</div>
					</div>
					{/* <Comments/> */}
				</div>
				</div>
				<Sidebar/>
			</div>
			</div>
		</div>
    )
  }
}

export default BlogDetails;
