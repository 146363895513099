import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Testimonial extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <div className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-70" >
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Emlak Kılavuzu</h6>
			          <h1 className="section-title">Geri dönüşlerimiz</h1>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__testimonial-slider-5-active slick-arrow-1">
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						İbrahim bey çok nazik, çok kibar ve çok iyi niyetli birisi olarak bizi karşıladı ve her sorumuza her sıkıntımıza yardımcı oldu. Aldığımız hizmet gayet güzeldi. Teşekkürler.</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Adnan Diril</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Emlak klavuzuna çok teşekkürlerimi sunuyorum önceden bir randevu günü ve saati olusturmamamiza rağmen aradığımız ayni gün yardımcı oldular. İbrahim Kildize Beye de ayrıca teşekkür ediyorum evi gezdirirken de ofiste sözleşmemizi yaparken de bizi çok güzel ağırladı.</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Zeynep Yıldız</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Emlak Kılavuzu markası ve Murat Bey ile yollarımız iyi ki kesişmiş, hem sundukları profesyonel destek ile kısa zamanda yatırımlarımızı değerlendirebildik, hem de güvenilir, kaliteli ve uzun yıllar sürdürebileceğimiz bir danışmanlık hizmetine kavuşmuş olduk. Tüm ekibe tekrar teşekkür ederim.</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Burcu Birol</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Ramazan Doğru Bey ile bir kiralama hizmeti işlemi gerçekleştirdik, kendisinin ilgisinden ve samimiyetinden çok memnun kaldık. Emlak sektöründe ki ön yargıları yıkacak bir yaklaşımı var. Kendisine Levitas Ailesi olarak çok teşekkür ediyoruz</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Şule KAYA</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Selam uzun zamandır mülk arayışımız vardı Gayrimenkul danışmanı Ramazan Doğru bey karşımıza çıktı iyikide çıktı hem bizi ev sahibi yaptı hem tüm işlemleri sorunsuz yönetti ilgi alakasindan ötürü teşekkür ederim kendisinden çok memnun kaldık soyadının hakkını veriyor.</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Sinan Besbas</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Merhaba,
İzmir de ikamet eden birisi olarak Yuvam Akarca evlerinde bulunan daire için İbrahim KILDIZE bey ile görüşüp git gel yapmadan ve kısa zamanda işlerin tamamlanmasını görüştük.İbrahim bey her türlü planlamayı kusursuzca yapıp işlemleri sorunsuzca halletti.
İbrahim beyin göstermiş olduğu ilgiye ve işini yapışındaki profesyonelliğine çok teşekkür ediyorum.</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Şenol Kuzu</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Selamlar Emlak kilavuzu ailesi Danismaniniz Ramazan Doğru vasitasiyla Derince dumlupinar mahallesinde dairemizi aldik ve aldigimiz gunden bu yana degerine deger katti Bizi ailece dogru yönlendirmesi dürüst ve seffaf olmasi kendisinden cok memnun kaldik gayrimenkul almadan Ramazan doğru bey ile gorusmenizi ve fikir alisverisi yapmanizi kesinlikle tavsiye ederim.  Geç kalinmis yorumum icinde affiniza siginiyorum Ramazan bey
</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Hamdi Kaya</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Emlak Kılavuzuna sonsuz teşekkürler, Satış danışmanı İbrahim bey gerek güler yüz, gerek bütün sorulara  hızlı bir şekilde sıkılmadan bize yanıt verdi, ev sahibi olabilmemiz için elinden gelen bütün çözüm olanaklarını önümüze sundu, işini ehli yapan insanlar, iyiki varlar. Teşekkürler Emlak Kılavuzu</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Asuman Gezici</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Emlak kılavuzu İbrahim Kıldıze ve Ramazan Doğru tarafından önerilen arsayı almaya geldim.Gösterdikleri ilgi alaka  ve çabalarıyla ilk defa geldiğim Başiskelede çok iyi ağırlandım .Arsamızı almış bulunmaktayız.Herşey için teşekkür ettim.
</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Salih Kıroğlu</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Emlakçılar ile çalışmaya hiç sıcak bakan biri değildim fakat Ramazan Doğru bey satın aldığım konut ile alakalı çok yardımcı oldu dürüst bir esnaf herşey için teşekkür ederim.</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Yunus Fatih Keskin</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Ramazan Doğru bey ve Emlak kılavuzu sayesinde yaptığımız alışverişten çok memnun kaldık ev işlerinde güven çok önemli kendileri bu güveni sonuna kadar hak ediyor kendilerine teşekkür ederim.</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Murat Deliktaş</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Merhaba izmit kocatepe Bulunan Mülkümüzün Satışı ile alakalı Ramazan Doğru bey ile görüştük ilgi alakası işine verdiği değer Samimi tavrı sıcak kanliligi Ve Bilgilendirmesi ile Mülkümüzun Satış yetkisini kendisine verdik Ramazan Bey ile çok yol alacağız</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Handan Tıngır</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Selamlar Emlak Kılavuzu ailesi, izmit kocatepe'de satılık dairemiz ile alakalı Ramazan doğru beyden satısı ile alakalı destek aldık kendisinden memnun kaldık herkese tavsiye ederiz Saygılar.</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Dr.Naif Kara</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Merhaba körfez bölgesinde satılık mulklerimiz var Ramazan doğru bey ile tanistik etkin ve aktif olarak bölgeye hizmet veriyor ilgisi alakası dan çok memnunuz herşey için teşekkür ederim kendisine Değerli biri bizim için</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Köksal Tayşi</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Merhaba  gölcük civarında mülkiyet sahibi olmamızda bize ilgi alaka samimi tavrıyla karşılayıp  yardımcı olan ibrahim beye  ve Emlak Kılavuzu'na çok teşekkür ederiz.</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Mustafa Yıldız</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
								Emlak Kılavuzu'nu Youtube'dan "Emlak Kılavuzu" kanalından Murat beyi izledikten sonra onlarla çalışma kararını aldım.
								Murat Bey ve İbrahim Bey ev satışımda ev satışımla ilgilendiler.
								Açık ara İzmit'in en iyi gayrımenkül danışmanlık şirketi, herkese tavsiye ederim emlak işlerinizi emlak kılavuzu ile yapın pişman olmazsınız.</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Ahmet Özler</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Merhaba İzmit Kocatepe bulunan mülkümüzün satışı ile alakalı Ramazan Doğru Bey ile görüştük ilgi alakası işine verdiği değer samimi tavrı sıcak kanlılığı ve bilgilendirmesi ile mülkümüzün satış yetkisini kendisine verdik Ramazan Bey ile çok yol alacağız</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>H.T.</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Gölcük civarında mülkiyet sahibi olmamızda bize ilgi alaka samimi tavrıyla karşılayıp yardımcı olan İbrahim Bey'e çok teşekkür ederiz... doğru adres</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>M.Y</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						İbrahim Bey le çalıştık, kendisi gayet profesyonel, ilgili ve şeffaftı... Alım-satım sürecini eksiksiz yürüttü.</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>H.G</h5>
			                <label></label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      {/*  */}
			    </div>
			  </div>
			</div>

        }
}

export default Testimonial