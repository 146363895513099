import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ProductSlider from './shop-components/product-slider-v1';
import ProductDetails from './shop-components/shop-details';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { useParams } from 'react-router-dom';
import { productList } from '../helper/staticDataList';

const Product_Details = () => {
    const {id} = useParams()
    const [itemDetail,setItemDetail]=React.useState()
    function findArrayElementByTitle(array, title) {
        return array.find((element) => {
          return element.id === title;
        })
      }
    React.useEffect(() => {
        
        setItemDetail(findArrayElementByTitle(productList, id))
    }, [])
    
    return <div>
        <Navbar />
        {/* <PageHeader headertitle="Product Details" customclass="mb-0" /> */}
        <ProductSlider pictureList={itemDetail?.imageList} />
        <hr></hr>
        <ProductDetails itemDetail={itemDetail?itemDetail:{}} />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default Product_Details

