import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import PortfolioDetails from './section-components/portfolio-details';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import Sidebar from './blog-components/sidebar';
import { useParams } from 'react-router-dom';
import { eventList } from '../helper/staticDataList';

const Portfolio_Details = () => {
    const { id } = useParams();
    const [portDetails, setPortDetails] = React.useState();
    function findArrayElementByTitle(array, title) {
        return array.find((element) => {
          return element.id === title;
        })
    }
    React.useEffect(() => {
        
        setPortDetails(findArrayElementByTitle(eventList, id))
    }, [])
    
    return <div>
        <Navbar />
        {/* <PageHeader headertitle="Portfolio Details" /> */}
        <hr></hr>
        <PortfolioDetails portDetails={portDetails?portDetails:{}} />
        
        <CallToActionV1 />
        <Footer />
    </div>
}

export default Portfolio_Details

