import React, { Component, useRef } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ContactForm = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const form = useRef();
  const [mailTemplate, setMailTemplate] = React.useState({
    to_email: "",
    to_name: "",
    to_message: "",
    to_gsm: "",
    to_topic: "",
  });
  const sendEmail = (e) => {
	  e.preventDefault();

    emailjs
      .sendForm(
        "service_g2ypfrv",
        "template_rwglbmu",
        form.current,
        "cQlZL0X7BR0K6wfQa"
      )
      .then(
        (result) => {
          setMailTemplate({
            to_email: "",
            to_name: "",
            to_message: "",
            to_gsm: "",
            to_topic: "",
          });
		  toast.success("Teşekkürler mesajını aldık.!")
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="ltn__contact-message-area mb-120 mb--100">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {/* Same as */}

		  <div className="container">
		  
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__form-box contact-form-box box-shadow white-bg">
              <h4 className="title-2">İletişime Geçelim!</h4>
              <form id="contact-form" ref={form} onSubmit={sendEmail}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="to_name"
                        placeholder="Adınızı Giriniz"
                        value={mailTemplate.to_name}
                        onChange={(e) => {
                          setMailTemplate({
                            ...mailTemplate,
                            to_name: e.target.value,
                          });
                        }}
                      />
                    </div>
					
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-email ltn__custom-icon">
                      <input
                        type="email"
                        name="to_email"
                        placeholder="Email Adresinizi Giriniz"
                        value={mailTemplate.to_email}
                        onChange={(e) => {
                          setMailTemplate({
                            ...mailTemplate,
                            to_email: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item">
                      <select
                        className="nice-select"
                        name="to_topic"

                        onchange="this.form.submit()"
                      >
                        <option>Konu</option>
                        <option>Bayilik</option>
                        <option>Danışmanlık Alım/Satım</option>
                        <option>Gayrimenkul Danışmanlığı Başvurusu</option>
                        <option>Yatırım Danışmanlığı</option>
                        <option>Öneri</option>
                        <option>Diğer</option>
                        <option></option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-phone ltn__custom-icon">
                      <input
                        type="text"
                        name="to_gsm"
                        placeholder="Telefon Numaranızı Giriniz"
                        value={mailTemplate.to_gsm}
                        onChange={(e) => {
                          setMailTemplate({
                            ...mailTemplate,
                            to_gsm: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-item input-item-textarea ltn__custom-icon">
                  <textarea
                    name="to_message"
                    placeholder="Mesajınızı Giriniz"
                    value={mailTemplate.to_message}
                    onChange={(e) => {
                      setMailTemplate({
                        ...mailTemplate,
                        to_message: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="btn-wrapper mt-0">
                  <button
                    className="btn theme-btn-1 btn-effect-1 "
                    type="submit"
                  >
                    Gönder
                  </button>
                </div>
                <p className="form-messege mb-0 mt-20" />
              </form>
            </div>
          </div>
        </div>
		  </div>
		  <ToastContainer />
	
    </div>
  );
};

export default ContactForm;
