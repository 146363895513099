import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { articleList, productList } from '../../helper/staticDataList';

class ShopDetails extends Component {

    render() {
		const itemDetail =this.props?.itemDetail
        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__shop-details-area pb-10">
				<div className="container">
				<div className="row">
					<div className="col-lg-8 col-md-12">
					<div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
						<div className="ltn__blog-meta">
						<ul>
							{/* <li className="ltn__blog-category">
							<Link to="#">Featured</Link>
							</li> */}
							{/* <li className="ltn__blog-category">
							<Link className="bg-orange" to="#">For Rent</Link>
							</li> */}
							<li className="ltn__blog-date">
							
							</li>
							{/* <li>
							<Link to="#"><i className="far fa-comments" />35 Comments</Link>
							</li> */}
						</ul>
						</div>
						<h1>{itemDetail.title}</h1>
						<label><span className="ltn__secondary-color"><i className="flaticon-pin" /></span> { itemDetail.localAdress}</label>
						<h4 className="title-2">PROJE HAKKINDA</h4>
						<p>{itemDetail.desc}</p>
						<h5 className="title-2">{itemDetail.subTitle}</h5>

						<p>{itemDetail.subDesc}</p>

						<h4 className="title-2">Galeri</h4>
						<div className="ltn__property-details-gallery mb-30">
							<div className="row">
								{itemDetail?.inImageList?.map((row) => {
									return (
										<div className="col-md-6">
										<a href={row} data-rel="lightcase:myCollection" width={"100%"}>
											<img className="mb-30" src={row} alt="Image" />
										</a>
										</div>
									)
								})}
					
				
								
						</div>
						</div>
						{/* <h4 className="title-2 mb-10">Amenities</h4>
						<div className="property-details-amenities mb-60">
						<div className="row">
							<div className="col-lg-4 col-md-6">
							<div className="ltn__menu-widget">
								<ul>
								<li>
									<label className="checkbox-item">Air Conditioning
									<input type="checkbox" defaultChecked="checked" />
									<span className="checkmark" />
									</label>
								</li>
								<li>
									<label className="checkbox-item">Gym
									<input type="checkbox" defaultChecked="checked" />
									<span className="checkmark" />
									</label>
								</li>
								<li>
									<label className="checkbox-item">Microwave
									<input type="checkbox" defaultChecked="checked" />
									<span className="checkmark" />
									</label>
								</li>
								<li>
									<label className="checkbox-item">Swimming Pool
									<input type="checkbox" defaultChecked="checked" />
									<span className="checkmark" />
									</label>
								</li>
								<li>
									<label className="checkbox-item">WiFi
									<input type="checkbox" defaultChecked="checked" />
									<span className="checkmark" />
									</label>
								</li>
								</ul>
							</div>
							</div>
							<div className="col-lg-4 col-md-6">
							<div className="ltn__menu-widget">
								<ul>
								<li>
									<label className="checkbox-item">Barbeque
									<input type="checkbox" defaultChecked="checked" />
									<span className="checkmark" />
									</label>
								</li>
								<li>
									<label className="checkbox-item">Recreation
									<input type="checkbox" defaultChecked="checked" />
									<span className="checkmark" />
									</label>
								</li>
								<li>
									<label className="checkbox-item">Microwave
									<input type="checkbox" defaultChecked="checked" />
									<span className="checkmark" />
									</label>
								</li>
								<li>
									<label className="checkbox-item">Basketball Cout
									<input type="checkbox" defaultChecked="checked" />
									<span className="checkmark" />
									</label>
								</li>
								<li>
									<label className="checkbox-item">Fireplace
									<input type="checkbox" defaultChecked="checked" />
									<span className="checkmark" />
									</label>
								</li>
								</ul>
							</div>
							</div>
							<div className="col-lg-4 col-md-6">
							<div className="ltn__menu-widget">
								<ul>
								<li>
									<label className="checkbox-item">Refrigerator
									<input type="checkbox" defaultChecked="checked" />
									<span className="checkmark" />
									</label>
								</li>
								<li>
									<label className="checkbox-item">Window Coverings
									<input type="checkbox" defaultChecked="checked" />
									<span className="checkmark" />
									</label>
								</li>
								<li>
									<label className="checkbox-item">Washer
									<input type="checkbox" defaultChecked="checked" />
									<span className="checkmark" />
									</label>
								</li>
								<li>
									<label className="checkbox-item">24x7 Security
									<input type="checkbox" defaultChecked="checked" />
									<span className="checkmark" />
									</label>
								</li>
								<li>
									<label className="checkbox-item">Indoor Game
									<input type="checkbox" defaultChecked="checked" />
									<span className="checkmark" />
									</label>
								</li>
								</ul>
							</div>
							</div>
						</div>
						</div> */}
						<h4 className="title-2">Konum</h4>
						<div className="property-details-google-map mb-60">
						<iframe src={itemDetail.google} width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} />
						</div>


						{/* APARTMENTS PLAN AREA END */}
						<h4 className="title-2">Tanıtım Videosu</h4>
						<div className="ltn__video-bg-img ltn__video-popup-height-500 bg-overlay-black-50 bg-image mb-60" data-bs-bg="https://www.zerayinsaat.com.tr/application/files/5416/7160/6897/1.webp">
						<a className="ltn__video-icon-2 ltn__video-icon-2-border---" href="https://www.youtube.com/embed/-Kc-wPNszRM" data-rel="lightcase:myCollection">
							<i className="fa fa-play" />
						</a>
						</div>
						{/* <div className="ltn__shop-details-tab-content-inner--- ltn__shop-details-tab-inner-2 ltn__product-details-review-inner mb-60">
						<h4 className="title-2">Customer Reviews</h4>
						<div className="product-ratting">
							<ul>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							<li><a href="#"><i className="fas fa-star-half-alt" /></a></li>
							<li><a href="#"><i className="far fa-star" /></a></li>
							<li className="review-total"> <a href="#"> ( 95 Reviews )</a></li>
							</ul>
						</div>
						<hr />
						
						<div className="ltn__comment-area mb-30">
							<div className="ltn__comment-inner">
							<ul>
								<li>
								<div className="ltn__comment-item clearfix">
									<div className="ltn__commenter-img">
									<img src={publicUrl+"assets/img/testimonial/1.jpg"} alt="Image" />
									</div>
									<div className="ltn__commenter-comment">
									<h6><a href="#">Adam Smit</a></h6>
									<div className="product-ratting">
										<ul>
										<li><a href="#"><i className="fas fa-star" /></a></li>
										<li><a href="#"><i className="fas fa-star" /></a></li>
										<li><a href="#"><i className="fas fa-star" /></a></li>
										<li><a href="#"><i className="fas fa-star-half-alt" /></a></li>
										<li><a href="#"><i className="far fa-star" /></a></li>
										</ul>
									</div>
									<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus, omnis fugit corporis iste magnam ratione.</p>
									<span className="ltn__comment-reply-btn">September 3, 2020</span>
									</div>
								</div>
								</li>
								<li>
								<div className="ltn__comment-item clearfix">
									<div className="ltn__commenter-img">
									<img src={publicUrl+"assets/img/testimonial/3.jpg"} alt="Image" />
									</div>
									<div className="ltn__commenter-comment">
									<h6><a href="#">Adam Smit</a></h6>
									<div className="product-ratting">
										<ul>
										<li><a href="#"><i className="fas fa-star" /></a></li>
										<li><a href="#"><i className="fas fa-star" /></a></li>
										<li><a href="#"><i className="fas fa-star" /></a></li>
										<li><a href="#"><i className="fas fa-star-half-alt" /></a></li>
										<li><a href="#"><i className="far fa-star" /></a></li>
										</ul>
									</div>
									<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus, omnis fugit corporis iste magnam ratione.</p>
									<span className="ltn__comment-reply-btn">September 2, 2020</span>
									</div>
								</div>
								</li>
								<li>
								<div className="ltn__comment-item clearfix">
									<div className="ltn__commenter-img">
									<img src={publicUrl+"assets/img/testimonial/2.jpg"} alt="Image" />
									</div>
									<div className="ltn__commenter-comment">
									<h6><a href="#">Adam Smit</a></h6>
									<div className="product-ratting">
										<ul>
										<li><a href="#"><i className="fas fa-star" /></a></li>
										<li><a href="#"><i className="fas fa-star" /></a></li>
										<li><a href="#"><i className="fas fa-star" /></a></li>
										<li><a href="#"><i className="fas fa-star-half-alt" /></a></li>
										<li><a href="#"><i className="far fa-star" /></a></li>
										</ul>
									</div>
									<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus, omnis fugit corporis iste magnam ratione.</p>
									<span className="ltn__comment-reply-btn">September 2, 2020</span>
									</div>
								</div>
								</li>
							</ul>
							</div>
						</div>
						
						<div className="ltn__comment-reply-area ltn__form-box mb-30">
							<form action="#">
							<h4>Add a Review</h4>
							<div className="mb-30">
								<div className="add-a-review">
								<h6>Your Ratings:</h6>
								<div className="product-ratting">
									<ul>
									<li><a href="#"><i className="fas fa-star" /></a></li>
									<li><a href="#"><i className="fas fa-star" /></a></li>
									<li><a href="#"><i className="fas fa-star" /></a></li>
									<li><a href="#"><i className="fas fa-star" /></a></li>
									<li><a href="#"><i className="fas fa-star" /></a></li>
									</ul>
								</div>
								</div>
							</div>
							<div className="input-item input-item-textarea ltn__custom-icon">
								<textarea placeholder="Type your comments...." defaultValue={""} />
							</div>
							<div className="input-item input-item-name ltn__custom-icon">
								<input type="text" placeholder="Type your name...." />
							</div>
							<div className="input-item input-item-email ltn__custom-icon">
								<input type="email" placeholder="Type your email...." />
							</div>
							<div className="input-item input-item-website ltn__custom-icon">
								<input type="text" name="website" placeholder="Type your website...." />
							</div>
							<label className="mb-0"><input type="checkbox" name="agree" /> Save my name, email, and website in this browser for the next time I comment.</label>
							<div className="btn-wrapper">
								<button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">Submit</button>
							</div>
							</form>
						</div>
						</div> */}
						{/* <h4 className="title-2">Related Properties</h4> */}
						{/* <div className="row">
						
						<div className="col-xl-6 col-sm-6 col-12 go-top">
							<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
							<div className="product-img">
								<Link to="/shop"><img src={publicUrl+"assets/img/product-3/1.jpg"} alt="#" /></Link>
								<div className="real-estate-agent">
								<div className="agent-img">
									<Link to="/team-details"><img src={publicUrl+"assets/img/blog/author.jpg"} alt="#" /></Link>
								</div>
								</div>
							</div>
							<div className="product-info">
								<div className="product-badge">
								<ul>
									<li className="sale-badg">For Rent</li>
								</ul>
								</div>
								<h2 className="product-title"><Link to="/shop">New Apartment Nice View</Link></h2>
								<div className="product-img-location">
								<ul>
									<li>
									<Link to="/shop"><i className="flaticon-pin" /> Belmont Gardens, Chicago</Link>
									</li>
								</ul>
								</div>
								<ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
								<li><span>3 </span>
									Bedrooms
								</li>
								<li><span>2 </span>
									Bathrooms
								</li>
								<li><span>3450 </span>
									square Ft
								</li>
								</ul>
								<div className="product-hover-action">
								<ul>
									<li>
									<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
										<i className="flaticon-expand" />
									</a>
									</li>
									<li>
									<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
										<i className="flaticon-heart-1" /></a>
									</li>
									<li>
									<Link to="/shop" title="Compare">
										<i className="flaticon-add" />
									</Link>
									</li>
								</ul>
								</div>
							</div>
							<div className="product-info-bottom">
								<div className="product-price">
								<span>$349,00<label>/Month</label></span>
								</div>
							</div>
							</div>
						</div>
						
						<div className="col-xl-6 col-sm-6 col-12 go-top">
							<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
							<div className="product-img">
								<Link to="/shop"><img src={publicUrl+"assets/img/product-3/2.jpg"} alt="#" /></Link>
								<div className="real-estate-agent">
								<div className="agent-img">
									<Link to="/team-details"><img src={publicUrl+"assets/img/blog/author.jpg"} alt="#" /></Link>
								</div>
								</div>
							</div>
							<div className="product-info">
								<div className="product-badge">
								<ul>
									<li className="sale-badg">For Sale</li>
								</ul>
								</div>
								<h2 className="product-title"><Link to="/shop">New Apartment Nice View</Link></h2>
								<div className="product-img-location">
								<ul>
									<li>
									<Link to="/shop"><i className="flaticon-pin" /> Belmont Gardens, Chicago</Link>
									</li>
								</ul>
								</div>
								<ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
								<li><span>3 </span>
									Bedrooms
								</li>
								<li><span>2 </span>
									Bathrooms
								</li>
								<li><span>3450 </span>
									square Ft
								</li>
								</ul>
								<div className="product-hover-action">
								<ul>
									<li>
									<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
										<i className="flaticon-expand" />
									</a>
									</li>
									<li>
									<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
										<i className="flaticon-heart-1" /></a>
									</li>
									<li>
									<a href="portfolio-details.html" title="Compare">
										<i className="flaticon-add" />
									</a>
									</li>
								</ul>
								</div>
							</div>
							<div className="product-info-bottom">
								<div className="product-price">
								<span>$349,00<label>/Month</label></span>
								</div>
							</div>
							</div>
						</div>
						</div> */}
					</div>
					</div>
					<div className="col-lg-4">
					<aside className="sidebar-area blog-sidebar ltn__right-sidebar">
				{/* Author Widget */}
				<div className="widget ltn__author-widget">
				<div className="ltn__author-widget-inner text-center">
					<img src={"assets/img/teamPictures/muratuygunx.jpg"} alt="Image" />
					<h5>Murat Uygun</h5>
					<h6 className="ltn__secondary-color">Kurucu</h6>
					<p></p>
					
					<div className="ltn__social-media">
					<ul>
						
					<li><a href="https://www.linkedin.com/company/emlak-kilavuzu/?originalSubdomain=tr" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
						<li><a href="https://www.instagram.com/murat__uygun__/" title="Instagram"><i className="fab fa-instagram" /></a></li>
						<li><a href="https://www.youtube.com/c/EmlakK%C4%B1lavuzu" title="Youtube"><i className="fab fa-youtube" /></a></li>
					</ul>
					</div>
				</div>
				</div>
				{/* Search Widget */}
				{/* <div className="widget ltn__search-widget">
				<h4 className="ltn__widget-title ltn__widget-title-border-2">Search Objects</h4>
				<form action="#">
					<input type="text" name="search" placeholder="Search your keyword..." />
					<button type="submit"><i className="fas fa-search" /></button>
				</form>
				</div> */}
				{/* Form Widget */}
				{/* <div className="widget ltn__form-widget">
				<h4 className="ltn__widget-title ltn__widget-title-border-2">Drop Messege For Book</h4>
				<form action="#">
					<input type="text" name="yourname" placeholder="Your Name*" />
					<input type="text" name="youremail" placeholder="Your e-Mail*" />
					<textarea name="yourmessage" placeholder="Write Message..." defaultValue={""} />
					<button type="submit" className="btn theme-btn-1">Send Messege</button>
				</form>
				</div> */}
				{/* Top Rated Product Widget */}
				{/* <div className="widget ltn__top-rated-product-widget">
				<h4 className="ltn__widget-title ltn__widget-title-border-2">Top Rated Product</h4>
				<ul>
					<li>
					<div className="top-rated-product-item clearfix go-top">
						<div className="top-rated-product-img">
						<Link to="/product-details"><img src={"https://www.timekocaeli.com/kcfinder/upload/files/2021%20KASIM/6182a74436320.jpg"} alt="#" /></Link>
						</div>
						<div className="top-rated-product-info">
						<div className="product-ratting">
							<ul>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							</ul>
						</div>
						<h6><Link to="/product-details">Luxury House In Greenville </Link></h6>
						<div className="product-price">
							<span>$30,000.00</span>
							<del>$35,000.00</del>
						</div>
						</div>
					</div>
					</li>
					<li>
					<div className="top-rated-product-item clearfix">
						<div className="top-rated-product-img">
						<Link to="/product-details"><img src={publicUrl+"assets/img/product/2.png"} alt="#" /></Link>
						</div>
						<div className="top-rated-product-info">
						<div className="product-ratting">
							<ul>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							</ul>
						</div>
						<h6><Link to="/product-details">Apartment with Subunits</Link></h6>
						<div className="product-price">
							<span>$30,000.00</span>
							<del>$35,000.00</del>
						</div>
						</div>
					</div>
					</li>
					<li>
					<div className="top-rated-product-item clearfix">
						<div className="top-rated-product-img">
						<Link to="/product-details"><img src={publicUrl+"assets/img/product/3.png"} alt="#" /></Link>
						</div>
						<div className="top-rated-product-info">
						<div className="product-ratting">
							<ul>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							<li><a href="#"><i className="fas fa-star-half-alt" /></a></li>
							<li><a href="#"><i className="far fa-star" /></a></li>
							</ul>
						</div>
						<h6><Link to="/product-details">3 Rooms Manhattan</Link></h6>
						<div className="product-price">
							<span>$30,000.00</span>
							<del>$35,000.00</del>
						</div>
						</div>
					</div>
					</li>
				</ul>
				</div> */}
				{/* Menu Widget (Category) */}
				{/* <div className="widget ltn__menu-widget ltn__menu-widget-2--- ltn__menu-widget-2-color-2--- go-top">
				<h4 className="ltn__widget-title ltn__widget-title-border-2">Top Categories</h4>
				<ul>
					<li><Link to="/blog-grid">Apartments <span>(26)</span></Link></li>
					<li><Link to="/blog-grid">Picture Stodio <span>(30)</span></Link></li>
					<li><Link to="/blog-grid">Office  <span>(71)</span></Link></li>
					<li><Link to="/blog-grid">Luxary Vilas <span>(56)</span></Link></li>
					<li><Link to="/blog-grid">Duplex House <span>(60)</span></Link></li>
				</ul>
				</div> */}
				{/* Popular Product Widget */}
				<div className="widget ltn__popular-product-widget">       
				<h4 className="ltn__widget-title ltn__widget-title-border-2">Projelerimiz</h4>                     
				<div className="row ltn__popular-product-widget-active slick-arrow-1">
						{/* ltn__product-item */}
						{productList.map((row) => {
							return (
								<div className="col-12">
								<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
									<div className="product-img">
											<Link to={`/product-details/${row.id}`}><img src={row.image} alt="#" /></Link>
									<div className="real-estate-agent">
										<div className="agent-img">
										{/* <Link to="/team-details"><img src={publicUrl+"assets/img/blog/author.jpg"} alt="#" /></Link> */}
										</div>
									</div>
									</div>
									<div className="product-info">
				
											<h2 className="product-title"><Link to={`/product-details/${row.id}`}>{row.title}</Link></h2>
									<div className="product-img-location">
										<ul>
										<li>
											<Link to={`/product-details/${row.id}`}><i className="flaticon-pin" />{row.localAdress}</Link>
										</li>
										</ul>
									</div>
									<ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
										
									
									</ul>
									</div>
								</div>
								</div>
							)
						})}
		

					{/*  */}
				</div>
				</div>
				{/* Popular Post Widget */}
				<div className="widget ltn__popular-post-widget">
				<h4 className="ltn__widget-title ltn__widget-title-border-2">Bloglar</h4>
					<ul>
						{articleList.map((row) => {
							return (
								<li>
								<div className="popular-post-widget-item clearfix">
									<div className="popular-post-widget-img">
											<Link to={`/blog-details${row.id}`}><img src={row.image} alt="#" /></Link>
									</div>
									<div className="popular-post-widget-brief">
											<h6><Link to={`/blog-details${row.id}`}>{row.title}</Link></h6>
									<div className="ltn__blog-meta">
										<ul>
										<li className="ltn__blog-date">
											
										</li>
										</ul>
									</div>
									</div>
								</div>
								</li>
							)
						})}
			

		
				</ul>
				</div>
				{/* Popular Post Widget (Twitter Post) */}
				{/* <div className="widget ltn__popular-post-widget ltn__twitter-post-widget">
				<h4 className="ltn__widget-title ltn__widget-title-border-2">Twitter Feeds</h4>
				<ul>
					<li>
					<div className="popular-post-widget-item clearfix">
						<div className="popular-post-widget-img">
						<Link to="/blog-details"><i className="fab fa-twitter" /></Link>
						</div>
						<div className="popular-post-widget-brief">
						<p>Carsafe - #Gutenberg ready 
							@wordpress
							Theme for Car Service, Auto Parts, Car Dealer available on 
							@website
							<a href="https://website.net">https://website.net</a></p>
						<div className="ltn__blog-meta">
							<ul>
							<li className="ltn__blog-date">
								<Link to="#"><i className="far fa-calendar-alt" />June 22, 2020</Link>
							</li>
							</ul>
						</div>
						</div>
					</div>
					</li>
					<li>
					<div className="popular-post-widget-item clearfix">
						<div className="popular-post-widget-img">
						<a href="blog-details.html"><i className="fab fa-twitter" /></a>
						</div>
						<div className="popular-post-widget-brief">
						<p>Carsafe - #Gutenberg ready 
							@wordpress
							Theme for Car Service, Auto Parts, Car Dealer available on 
							@website
							<a href="https://website.net">https://website.net</a></p>
						<div className="ltn__blog-meta">
							<ul>
							<li className="ltn__blog-date">
								<Link to="#"><i className="far fa-calendar-alt" />June 22, 2020</Link>
							</li>
							</ul>
						</div>
						</div>
					</div>
					</li>
					<li>
					<div className="popular-post-widget-item clearfix">
						<div className="popular-post-widget-img">
						<Link to="/blog-details"><i className="fab fa-twitter" /></Link>
						</div>
						<div className="popular-post-widget-brief">
						<p>Carsafe - #Gutenberg ready 
							@wordpress
							Theme for Car Service, Auto Parts, Car Dealer available on 
							@website
							<a href="https://website.net">https://website.net</a></p>
						<div className="ltn__blog-meta">
							<ul>
							<li className="ltn__blog-date">
								<Link to="#"><i className="far fa-calendar-alt" />June 22, 2020</Link>
							</li>
							</ul>
						</div>
						</div>
					</div>
					</li>
				</ul>
				</div> */}
				{/* Social Media Widget */}
				<div className="widget ltn__social-media-widget">
				<h4 className="ltn__widget-title ltn__widget-title-border-2">Sosyal Medyalarımız</h4>
				<div className="ltn__social-media-2">
					<ul>
					<li><a href="https://www.facebook.com/emlakkilavuzu/?hc_ref=ARSl3nI4vn6XcaHYc4xl6P0Rfg1vFO_kQOMa_BikB26Ico1U3_55sOrrVzpl_LCn674&fref=nf&__xts__[0]=68.ARA5H46bYpV6ux2gh4NWiNgfgZ1uRJNRF5qnSMBAZSLwlCO79Yo7hjby5wMdCK5Pkvn48v05pTnKlrMSz8Aka2A4mXQGzArU0SJrlldL1fPFIk8_8NycVh5ab-vptv5OvoyPuW2kGR2B0q_XF-g6xrVY4NxFoCVEdB2O-0Vy7ONCdOaxt_Ur4oBVoJseM-xiXkxuxILgjcMVpylYCboJ1uoeJRrfLvEXX3n7NiVEPnVFxYZAZLpPdOkIXAVMOkRef6gmXGIyF_VUeLtVrKe3iz84zhIrZPBWR7fZTT73_vsLDSv6fVM" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
					<li><a href="https://twitter.com/brokermurat" title="Twitter"><i className="fab fa-twitter" /></a></li>
					<li><a href="https://www.instagram.com/emlakkilavuzu/" title="Instagram"><i className="fab fa-instagram" /></a></li>
					<li><a href="https://emlakkilavuzu.sahibinden.com/" title="Dribbble"><i className="fab fa-dribbble" /></a></li>
					<li><a href="https://www.zingat.com/emlak-kilavuzu-59042g" title="Dribbble"><img src={publicUrl+"assets/zingat.png"} color='white' width={20	} height={19}></img></a></li>
					<li><a href="https://www.linkedin.com/company/emlak-kilavuzu/?originalSubdomain=tr" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
					<li><a href="https://www.youtube.com/@EmlakKilavuzu" title="Youtube"><i className="fab fa-youtube" /></a></li>
					</ul>
				</div>
				</div>
				{/* Tagcloud Widget */}
				{/* <div className="widget ltn__tagcloud-widget go-top">
				<h4 className="ltn__widget-title ltn__widget-title-border-2">Popular Tags</h4>
				<ul>
					<li><Link to="/blog-grid">Popular</Link></li>
					<li><Link to="/blog-grid">desgin</Link></li>
					<li><Link to="/blog-grid">ux</Link></li>
					<li><Link to="/blog-grid">usability</Link></li>
					<li><Link to="/blog-grid">develop</Link></li>
					<li><Link to="/blog-grid">icon</Link></li>
					<li><Link to="/blog-grid">Car</Link></li>
					<li><Link to="/blog-grid">Service</Link></li>
					<li><Link to="/blog-grid">Repairs</Link></li>
					<li><Link to="/blog-grid">Auto Parts</Link></li>
					<li><Link to="/blog-grid">Oil</Link></li>
					<li><Link to="/blog-grid">Dealer</Link></li>
					<li><Link to="/blog-grid">Oil Change</Link></li>
					<li><Link to="/blog-grid">Body Color</Link></li>
				</ul>
				</div> */}
				{/* Banner Widget */}
				<div className="widget ltn__banner-widget d-none go-top">
				<Link to="/shop"><img src={publicUrl+"assets/img/banner/2.jpg"} alt="#" /></Link>
				</div>
			</aside>
					</div>
				</div>
				</div>
			</div>
        }
}

export default ShopDetails