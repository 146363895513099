import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ContactInfo extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__contact-address-area mb-90">
				<div className="container">
				<div className="row">
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<div className="ltn__contact-address-icon">
						<img src={publicUrl+"assets/img/emlakresimlerix/contact1.png"} alt="Icon Image" />
						</div>
						<h3>Email Adresimiz</h3>
						<p>info@emlakkilavuzu.com <br />
						<hr></hr>
						
						</p>
					</div>
					</div>
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<div className="ltn__contact-address-icon">
						<img src={publicUrl+"assets/img/emlakresimlerix/contact2.png"} alt="Icon Image" />
						</div>
						<h3>Telefon Numaramız</h3>
						
						<p>+0 262 999 11 35</p>
						<hr></hr>
						
					</div>
					</div>
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<div className="ltn__contact-address-icon">
						<img src={publicUrl+"assets/img/emlakresimlerix/contact3.png"} alt="Icon Image" />
						</div>
						<h3>Ofis Adresimiz</h3>
						<p>Ovacık Mahallesi D-100 Karayolu Caddesi No:10/1 Kat:1 <br />
						Başiskele, Kocaeli<br></br>Türkiye</p>
						
					</div>
					</div>
				</div>
				</div>
			</div>
        }
}

export default ContactInfo