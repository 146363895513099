import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import TeamV1 from './section-components/team-v2';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import PortfolioV1 from './section-components/portfolio-v2';


const Team_V1 = () => {
    let publicUrl = process.env.PUBLIC_URL+'/'

    return <div>
        <Navbar />
        {/* <PageHeader headertitle="Our Agent" subheader="Agent" /> */}
        <hr></hr>
        <div className="row"  style={{width:"80%",alignSelf:"center",alignItems:"center",justifyContent:"center",paddingLeft:"20%"}}>
                    <div className="col-lg-5">
                    <div className="ltn__team-details-member-info text-center mb-40">
                        <div className="team-details-img">
                        <img src={publicUrl+"assets/img/teamPictures/uygunmurat.jpg"} alt="Team Member Image" height={460} width={350} />
                        </div>
                        <h2>Murat Uygun</h2>
                        <h6 className="ltn__secondary-color">Kurucu</h6>
                        <div className="ltn__social-media-3">
                        <ul>
                        <li><a href="https://www.linkedin.com/in/murat-uygun/" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
						<li><a href="https://www.instagram.com/murat__uygun__/" title="Instagram"><i className="fab fa-instagram" /></a></li>
						<li><a href="https://www.youtube.com/c/EmlakK%C4%B1lavuzu" title="Youtube"><i className="fab fa-youtube" /></a></li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-8">
                    <div className="ltn__team-details-member-info-details">
                        
                        <div className="row">
                        <div className="col-lg-6">
                            <div className="ltn__team-details-member-about">
                            <ul>
                                
                            
                                
                                {/* <li><strong>Practice Area:</strong> Property Seller</li> */}
                            </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="ltn__team-details-member-about">
                            <ul>
                                
                                
                                
                            </ul>
                            </div>
                        </div>
                        </div>
                        
                        
                    </div>
                    </div>
                </div>
        <TeamV1 />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default Team_V1

