import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { articleList } from '../../helper/staticDataList';
import Sidebar from './sidebar';
class BlogList extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL+'/'
    return (
		<div className="ltn__blog-area mb-120">
			<div className="container">
			<div className="row">
				<div className="col-lg-8">
				<div className="ltn__blog-list-wrap">
							{/* Blog Item */}
							{articleList.map((row) => {
								return (
									<div className="ltn__blog-item ltn__blog-item-5 go-top">
									<div className="ltn__blog-img">
											<Link to={`/blog-details/${row.id}`}><img src={row.image} alt="Image" /></Link>
									</div>
									<div className="ltn__blog-brief">
										<div className="ltn__blog-meta">
										<ul>
											<li className="ltn__blog-category">
														<Link to="/blog-grid">{row.tag?.[0]}</Link>
											</li>
										</ul>
										</div>
											<h3 className="ltn__blog-title"><Link to={`/blog-details/${row.id}`}>{row.title}</Link></h3>
										<div className="ltn__blog-meta">
										<ul>
											
											<li className="ltn__blog-date">
											<i className="far fa-calendar-alt" />Şubat 16, 2023
											</li>
										</ul>
										</div>
											<p>{row.descList?.[0].subTitle.slice(0,300)}...</p>
										<div className="ltn__blog-meta-btn">
										<div className="ltn__blog-meta">
											<ul>
											<li className="ltn__blog-author">
												<Link to="/blog-grid">Yazar: {row.writerBy} </Link>
											</li>
											</ul>
										</div>
										<div className="ltn__blog-btn">
											<Link to={`/blog-details/${row.id}`}><i className="fas fa-arrow-right" />Devamını gör</Link>
										</div>
										</div>
									</div>
									</div>
						
								)
							})}
			

				</div>
				{/* <div className="row">
					<div className="col-lg-12">
					<div className="ltn__pagination-area text-center">
						<div className="ltn__pagination">
						<ul>
							<li><Link to="#"><i className="fas fa-angle-double-left" /></Link></li>
							<li><Link to="#">1</Link></li>
							<li className="active"><Link to="#">2</Link></li>
							<li><Link to="#">3</Link></li>
							<li><Link to="#">...</Link></li>
							<li><Link to="#">10</Link></li>
							<li><Link to="#"><i className="fas fa-angle-double-right" /></Link></li>
						</ul>
						</div>
					</div>
					</div>
				</div> */}
				</div>
				<Sidebar/>
			</div>
			</div>
		</div>
    )
  }
}

export default BlogList;
