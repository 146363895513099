import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { articleList, eventList, productList } from '../../helper/staticDataList';
import BlogRightSidebarPage from '../blog';
import Sidebar from '../blog-components/sidebar';


class PortfolioDetails extends Component {
	
    render() {
		const portDetail =this.props?.portDetails
        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__page-details-area ltn__portfolio-details-area mb-105">
                <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                    <div className="ltn__page-details-inner ltn__portfolio-details-inner">
                        <div className="ltn__blog-img">
                        <img src={portDetail.image} alt="Image" />
						</div>
						<h4>{portDetail.title}</h4>
						<p> {portDetail.desc}</p>
                     
						<div className="row">
							{portDetail.galery ?
								
								portDetail.galery.map((row) => {
									return (
										<div className="col-lg-6">
										<div className="ltn__testimonial-item ltn__testimonial-item-3">
										<div className="ltn__testimonial-img">
											<img src={row} alt="Image" />
										</div>
							
										</div>
										</div> 
									)
								})
								
							: null}
							
		
							
            
             
                        </div>
						<p>{portDetail.desc2}</p>

                     
                        <div className="ltn__service-list-menu text-uppercase mt-50 d-none">
                        <ul>
                            <li><i className="fas fa-car" /> Front Brakes Repair <span className="service-price">$225.95 <span>Plus Parts</span></span> </li>
                            <li><i className="fas fa-life-ring" /> Rear Brakes Repair <span className="service-price">$225.95 <span>Plus Parts</span></span> </li>
                            <li><i className="fas fa-cog" /> Axle <span className="service-price">$225.95 <span>Plus Parts</span></span> </li>
                            <li><i className="fas fa-car" /> Starters / Alternators <span className="service-price">$225.95 <span>Plus Parts</span></span> </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4">
					<aside className="sidebar-area blog-sidebar ltn__right-sidebar">
				{/* Author Widget */}
				<div className="widget ltn__author-widget">
				<div className="ltn__author-widget-inner text-center">
					<img src={publicUrl+"assets/img/teamPictures/muratuygunx.jpg"} alt="Image" />
					<h5>Murat Uygun</h5>
					<h6 className="ltn__secondary-color">Kurucu</h6>
					
					
					
					<div className="ltn__social-media">
					<ul>
						
						<li><a href="https://twitter.com/brokermurat" title="Twitter"><i className="fab fa-twitter" /></a></li>
						<li><a href="https://www.instagram.com/murat__uygun__/" title="Instagram"><i className="fab fa-instagram" /></a></li>
						<li><a href="https://www.youtube.com/c/EmlakK%C4%B1lavuzu" title="Youtube"><i className="fab fa-youtube" /></a></li>
					</ul>
					</div>
				</div>
				</div>
				{/* Search Widget */}
				{/* <div className="widget ltn__search-widget">
				<h4 className="ltn__widget-title ltn__widget-title-border-2">Search Objects</h4>
				<form action="#">
					<input type="text" name="search" placeholder="Search your keyword..." />
					<button type="submit"><i className="fas fa-search" /></button>
				</form>
				</div> */}
				{/* Form Widget */}
				{/* <div className="widget ltn__form-widget">
				<h4 className="ltn__widget-title ltn__widget-title-border-2">Drop Messege For Book</h4>
				<form action="#">
					<input type="text" name="yourname" placeholder="Your Name*" />
					<input type="text" name="youremail" placeholder="Your e-Mail*" />
					<textarea name="yourmessage" placeholder="Write Message..." defaultValue={""} />
					<button type="submit" className="btn theme-btn-1">Send Messege</button>
				</form>
				</div> */}
				{/* Top Rated Product Widget */}
				{/* <div className="widget ltn__top-rated-product-widget">
				<h4 className="ltn__widget-title ltn__widget-title-border-2">Top Rated Product</h4>
				<ul>
					<li>
					<div className="top-rated-product-item clearfix go-top">
						<div className="top-rated-product-img">
						<Link to="/product-details"><img src={"https://www.timekocaeli.com/kcfinder/upload/files/2021%20KASIM/6182a74436320.jpg"} alt="#" /></Link>
						</div>
						<div className="top-rated-product-info">
						<div className="product-ratting">
							<ul>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							</ul>
						</div>
						<h6><Link to="/product-details">Luxury House In Greenville </Link></h6>
						<div className="product-price">
							<span>$30,000.00</span>
							<del>$35,000.00</del>
						</div>
						</div>
					</div>
					</li>
					<li>
					<div className="top-rated-product-item clearfix">
						<div className="top-rated-product-img">
						<Link to="/product-details"><img src={publicUrl+"assets/img/product/2.png"} alt="#" /></Link>
						</div>
						<div className="top-rated-product-info">
						<div className="product-ratting">
							<ul>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							</ul>
						</div>
						<h6><Link to="/product-details">Apartment with Subunits</Link></h6>
						<div className="product-price">
							<span>$30,000.00</span>
							<del>$35,000.00</del>
						</div>
						</div>
					</div>
					</li>
					<li>
					<div className="top-rated-product-item clearfix">
						<div className="top-rated-product-img">
						<Link to="/product-details"><img src={publicUrl+"assets/img/product/3.png"} alt="#" /></Link>
						</div>
						<div className="top-rated-product-info">
						<div className="product-ratting">
							<ul>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							<li><a href="#"><i className="fas fa-star" /></a></li>
							<li><a href="#"><i className="fas fa-star-half-alt" /></a></li>
							<li><a href="#"><i className="far fa-star" /></a></li>
							</ul>
						</div>
						<h6><Link to="/product-details">3 Rooms Manhattan</Link></h6>
						<div className="product-price">
							<span>$30,000.00</span>
							<del>$35,000.00</del>
						</div>
						</div>
					</div>
					</li>
				</ul>
				</div> */}
				{/* Menu Widget (Category) */}
				{/* <div className="widget ltn__menu-widget ltn__menu-widget-2--- ltn__menu-widget-2-color-2--- go-top">
				<h4 className="ltn__widget-title ltn__widget-title-border-2">Top Categories</h4>
				<ul>
					<li><Link to="/blog-grid">Apartments <span>(26)</span></Link></li>
					<li><Link to="/blog-grid">Picture Stodio <span>(30)</span></Link></li>
					<li><Link to="/blog-grid">Office  <span>(71)</span></Link></li>
					<li><Link to="/blog-grid">Luxary Vilas <span>(56)</span></Link></li>
					<li><Link to="/blog-grid">Duplex House <span>(60)</span></Link></li>
				</ul>
				</div> */}
				{/* Popular Product Widget */}
				<div className="widget ltn__popular-product-widget">       
				<h4 className="ltn__widget-title ltn__widget-title-border-2">Projelerimiz</h4>                     
				<div className="row ltn__popular-product-widget-active slick-arrow-1">
						{/* ltn__product-item */}
						{productList.map((row) => {
							return (
								<div className="col-12">
								<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
									<div className="product-img">
											<Link to={`/product-details/${row.id}`}><img src={row.image} alt="#" /></Link>
									<div className="real-estate-agent">
										<div className="agent-img">
										{/* <Link to="/team-details"><img src={publicUrl+"assets/img/blog/author.jpg"} alt="#" /></Link> */}
										</div>
									</div>
									</div>
									<div className="product-info">
				
											<h2 className="product-title"><Link to={`/product-details/${row.id}`}>{row.title}</Link></h2>
									<div className="product-img-location">
										<ul>
										<li>
											<Link to={`/product-details/${row.id}`}><i className="flaticon-pin" />{row.localAdress}</Link>
										</li>
										</ul>
									</div>
									
									</div>
								</div>
								</div>
							)
						})}
		

					{/*  */}
				</div>
				</div>
				{/* Popular Post Widget */}
				<div className="widget ltn__popular-post-widget">
				<h4 className="ltn__widget-title ltn__widget-title-border-2">Bloglar</h4>
					<ul>
						{articleList.map((row) => {
							return (
								<li>
								<div className="popular-post-widget-item clearfix">
									<div className="popular-post-widget-img">
											<Link to={`/blog-details${row.id}`}><img src={row.image} alt="#" /></Link>
									</div>
									<div className="popular-post-widget-brief">
											<h6><Link to={`/blog-details${row.id}`}>{row.title}</Link></h6>
									<div className="ltn__blog-meta">
										<ul>
										<li className="ltn__blog-date">
											<Link to={`/blog-details${row.id}`}><i className="far fa-calendar-alt" />Şubat 16, 2023</Link>
										</li>
										</ul>
									</div>
									</div>
								</div>
								</li>
							)
						})}
			

		
				</ul>
				</div>
				{/* Popular Post Widget (Twitter Post) */}
				{/* <div className="widget ltn__popular-post-widget ltn__twitter-post-widget">
				<h4 className="ltn__widget-title ltn__widget-title-border-2">Twitter Feeds</h4>
				<ul>
					<li>
					<div className="popular-post-widget-item clearfix">
						<div className="popular-post-widget-img">
						<Link to="/blog-details"><i className="fab fa-twitter" /></Link>
						</div>
						<div className="popular-post-widget-brief">
						<p>Carsafe - #Gutenberg ready 
							@wordpress
							Theme for Car Service, Auto Parts, Car Dealer available on 
							@website
							<a href="https://website.net">https://website.net</a></p>
						<div className="ltn__blog-meta">
							<ul>
							<li className="ltn__blog-date">
								<Link to="#"><i className="far fa-calendar-alt" />June 22, 2020</Link>
							</li>
							</ul>
						</div>
						</div>
					</div>
					</li>
					<li>
					<div className="popular-post-widget-item clearfix">
						<div className="popular-post-widget-img">
						<a href="blog-details.html"><i className="fab fa-twitter" /></a>
						</div>
						<div className="popular-post-widget-brief">
						<p>Carsafe - #Gutenberg ready 
							@wordpress
							Theme for Car Service, Auto Parts, Car Dealer available on 
							@website
							<a href="https://website.net">https://website.net</a></p>
						<div className="ltn__blog-meta">
							<ul>
							<li className="ltn__blog-date">
								<Link to="#"><i className="far fa-calendar-alt" />June 22, 2020</Link>
							</li>
							</ul>
						</div>
						</div>
					</div>
					</li>
					<li>
					<div className="popular-post-widget-item clearfix">
						<div className="popular-post-widget-img">
						<Link to="/blog-details"><i className="fab fa-twitter" /></Link>
						</div>
						<div className="popular-post-widget-brief">
						<p>Carsafe - #Gutenberg ready 
							@wordpress
							Theme for Car Service, Auto Parts, Car Dealer available on 
							@website
							<a href="https://website.net">https://website.net</a></p>
						<div className="ltn__blog-meta">
							<ul>
							<li className="ltn__blog-date">
								
							</li>
							</ul>
						</div>
						</div>
					</div>
					</li>
				</ul>
				</div> */}
				{/* Social Media Widget */}
				<div className="widget ltn__social-media-widget">
				<h4 className="ltn__widget-title ltn__widget-title-border-2">Sosyal Medyalarımız</h4>
				<div className="ltn__social-media-2">
					<ul>
					<li><a href="https://www.facebook.com/emlakkilavuzu/?hc_ref=ARSl3nI4vn6XcaHYc4xl6P0Rfg1vFO_kQOMa_BikB26Ico1U3_55sOrrVzpl_LCn674&fref=nf&__xts__[0]=68.ARA5H46bYpV6ux2gh4NWiNgfgZ1uRJNRF5qnSMBAZSLwlCO79Yo7hjby5wMdCK5Pkvn48v05pTnKlrMSz8Aka2A4mXQGzArU0SJrlldL1fPFIk8_8NycVh5ab-vptv5OvoyPuW2kGR2B0q_XF-g6xrVY4NxFoCVEdB2O-0Vy7ONCdOaxt_Ur4oBVoJseM-xiXkxuxILgjcMVpylYCboJ1uoeJRrfLvEXX3n7NiVEPnVFxYZAZLpPdOkIXAVMOkRef6gmXGIyF_VUeLtVrKe3iz84zhIrZPBWR7fZTT73_vsLDSv6fVM" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
					
					<li><a href="https://www.instagram.com/emlakkilavuzu/" title="Instagram"><i className="fab fa-instagram" /></a></li>
					<li><a href="https://emlakkilavuzu.sahibinden.com/" title="Dribbble"><i className="fab fa-dribbble" /></a></li>
					<li><a href="https://www.zingat.com/emlak-kilavuzu-59042g" title="Dribbble"><img src={publicUrl+"assets/zingat.png"} color='white' width={20	} height={19}></img></a></li>
					<li><a href="https://www.linkedin.com/company/emlak-kilavuzu/?originalSubdomain=tr" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
					<li><a href="https://www.youtube.com/@EmlakKilavuzu" title="Youtube"><i className="fab fa-youtube" /></a></li>
					</ul>
				</div>
				</div>
				{/* Tagcloud Widget */}
				{/* <div className="widget ltn__tagcloud-widget go-top">
				<h4 className="ltn__widget-title ltn__widget-title-border-2">Popular Tags</h4>
				<ul>
					<li><Link to="/blog-grid">Popular</Link></li>
					<li><Link to="/blog-grid">desgin</Link></li>
					<li><Link to="/blog-grid">ux</Link></li>
					<li><Link to="/blog-grid">usability</Link></li>
					<li><Link to="/blog-grid">develop</Link></li>
					<li><Link to="/blog-grid">icon</Link></li>
					<li><Link to="/blog-grid">Car</Link></li>
					<li><Link to="/blog-grid">Service</Link></li>
					<li><Link to="/blog-grid">Repairs</Link></li>
					<li><Link to="/blog-grid">Auto Parts</Link></li>
					<li><Link to="/blog-grid">Oil</Link></li>
					<li><Link to="/blog-grid">Dealer</Link></li>
					<li><Link to="/blog-grid">Oil Change</Link></li>
					<li><Link to="/blog-grid">Body Color</Link></li>
				</ul>
				</div> */}
				{/* Banner Widget */}
				<div className="widget ltn__banner-widget d-none go-top">
				<Link to="/shop"><img src={publicUrl+"assets/img/banner/2.jpg"} alt="#" /></Link>
				</div>
			</aside>
                    </div>
                </div>
                </div>
            </div>
        }
}

export default PortfolioDetails