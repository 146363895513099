import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
class CopyRight extends Component {
	constructor(props){ 
		super(props) 
			
		// Set initial state 
		this.state = {modelOpen : 
			  false} 
			
		// Binding this keyword 
		this.closeModel = this.closeModel.bind(this)
		this.openModel = this.openModel.bind(this)
		// this.closeModel = this.closeModel.bind(this) 
		// this.openModel = this.openModel.bind(this) 
		
	  } 
		
	   closeModel() {
		this.setState({modelOpen : false}) 
		}
	 openModel() {
		this.setState({modelOpen : true}) 
		}
	render() {
		const customStyles = {

			content: {
				top: '50%',
			  left: '50%',
			  right: 'auto',
			  bottom: 'auto',
			  marginRight: '-50%',
			  transform: 'translate(-50%, -50%)',
			},
		  };


        let publicUrl = process.env.PUBLIC_URL+'/'
		let subtitle;
		
	  
        return (
			<div className="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5">
			<div className="container-fluid ltn__border-top-2">
				<div className="row">
				<div className="col-md-6 col-12">
					<div className="ltn__copyright-design clearfix">
					<p>Tasarım @Vi Nå Agency </p>
					</div>
				</div>
				<div className="col-md-6 col-12 align-self-center">
					<div className="ltn__copyright-menu text-end">
					<ul className="go-top">
						
						<li><a style={{cursor:"pointer"}} onClick={()=>{this.openModel()}}>Yasal Uyarı &amp; Kullanım Koşulları</a></li>
						
					</ul>
					</div>
				</div>
				</div>
				</div>
				
      <Modal
        isOpen={this.state.modelOpen}
        
        onRequestClose={this.closeModel}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2 >YASAL UYARI VE KULLANIM KOŞULLARI</h2>
        {/* <button onClick={closeModal}>close</button> */}
        <div>

Emlak Kılavuzu Türkiye web sitesine hoşgeldiniz. İş bu web sitesi, Türkiye sınırları dahilinde Emlak Kılavuzu Türkiye ve Franchise Ofislerinin portföylerinde bulunan gayrimenkul alım-satımı ile kiralanması hususunda bilgilere erişim sağlamak için oluşturulmuştur.
Bu sitedeki her türlü ses, görüntü, yazı içeren bilgi ve belge, ticari marka ve her tür fikri mülkiyet, Emlak Kılavuzu Türkiye ya da bağlı şirketlerine aittir ya da sahiplerinin izni ile kullanılmaktadır ve telif hakları kapsamındadır. İzinsiz değişiklik yapmak, kopyalamak, kiralamak, ödünç vermek, iletmek ve yayınlamak yasaktır. Bu siteden alınan her türlü ses, görüntü, yazı içeren bilgi ve belge satılamaz ya da herhangi bir kâr amacıyla dağıtılamaz.
İleride söz konusu şartları okumamış veya okumuş olup da bunlara uygun davranmamış olmanızdan kaynaklanabilecek olan her türlü maddi ve manevi zarara ilişkin talebinizden www.emlakkilavuzu.com Web Sitesi'nin (“Web Sitesi”), Web Sitesi’ndeki her türlü içeriğin hazırlayıcılarının, çalışanlarının, Tek Şirketler Grubu’nun yetkilendirdikleri kişi ve kuruluşların hiçbir hukuki ve cezai sorumluluğu bulunmamaktadır. www.emlakkilavuzu.com Web Sitesi’ni ziyaret edenlerin, reklam ve sponsorluk veren şirketlerin, Web Sitesi’nde yayınlamış olabilecekleri yazı, bilgi, yorum, haber, görüntülerle Emlak Kılavuzu logosunun, başkaca web sitelerinde kullanımı, web, posta, mobil site vb. hizmetlerinin ve kullanıcılar tarafından RE/MAX TÜRKİYE sunucularına yüklenip yayınlanan her türlü içeriğin kullanımı suretiyle edinilen bilgi ve yorumlar dâhil ve fakat bu sayılan kişiler ya da yayınlarla sınırlı olmaksızın, internetin genel teamüller doğrultusundaki yapısı gereği sorumluluk ve yetkisinde olmayan herhangi bir içerikten, Emlak Kılavuzu Türkiye 'nin hukuki, cezai ve/veya şahsi hiçbir sorumluluğu bulunmamakta olup, işbu Web Sitesi ziyaretçileri bu hususu bu siteye girdikleri andan itibaren kabul ve beyan ederler.
www.emlakkilavuzu.com sitesindeki bağlantılar sizi Emlak Kılavuzu ağının dışına götürebilir ve Emlak Kılavuzu, bu sitelerin içeriği, doğruluğu ya da işleviyle ilgili olarak, hiç bir sorumluluk üstlenmez. Bu bağlantılar iyi niyet çerçevesinde sunulmaktadır ve Emlak Kılavuzu, bağlantısını verdiğimiz sitelerde daha sonra olabilecek herhangi bir değişiklikten sorumlu değildir. Tekfen veya bağlı şirketleri ya da bu sitenin oluşturulması, üretilmesi ya da iletilmesinde yer alan diğer herhangi bir üçüncü taraf bu siteye erişim ya da sitenin kullanımından doğabilecek herhangi bir doğrudan, dolaylı, arızi, tali ya da cezai zarar, ziyan, hasar veya sorumluluktan ötürü sorumlu tutulamaz.
www.emlakkilavuzu.com Sitesi’nde yer alan her türlü bilgi, istatistiki şekil ve değer, güvenilirliği genel teamüller çerçevesinde kabul edilen kaynaklardan elde edilerek derlenmiş olup, doğrulukları ve yeterlilikleri hiç bir şekilde Emlak Kılavuzu tarafından garanti edilmemektedir.
Web Sitesi’nde yer alan bilgi, değerlendirme, yorum ve istatistiki şekil ve değerlerin kullanımı sonucunda doğabilecek doğrudan veya dolaylı maddi ve/veya manevi menfi ve/veya müsbet zararlardan ve olası sair zarar ve masraflardan dolayı Emlak Kılavuzu’nun hiç bir hukuki ve cezai sorumluluğu mevcut değildir.
İş başvuruları hariç olmak üzere, İnternet aracılığıyla bize ilettiğiniz her tür yorum, öneri, fikir, grafik ve sair bilgiler, Emlak Kılavuzu veya bağlı şirketleri tarafından, size veya başka herhangi bir kişiye herhangi bir ödeme yapılmaksızın, ticari veya gayri ticari bir amaçla kullanılabilecektir. Emlak Kılavuzu veya bağlı şirketleri, iş başvuruları hariç olmak üzere kendilerine iletilen bu tür bilgileri gizli tutmak zorunda değildir.
Emlak Kılavuzu, işbu hüküm ve şartları dilediği zaman önceden haber vermek zorunda olmaksızın tek taraflı olarak değiştirme ve güncelleme hakkına haizdir.</div>

      </Modal>
			</div>
        )
    }
}


export default CopyRight