import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class FeaturesV1 extends Component {

    render() {

    let publicUrl = process.env.PUBLIC_URL+'/'

    let customClass = this.props.customClass ? this.props.customClass :''

    return <div className={ customClass } >
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Emlak Kılavuzu</h6>
			          <h1 className="section-title">Hizmetlerimiz</h1>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__custom-gutter--- justify-content-center go-top">
			      <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/emlakresimlerix/emlak31-1.png"} alt="#" />
			          </div>
			          <div className="ltn__feature-info">
			            <h3>Gayrimenkul Alın!</h3>
			            <p>Yatırım hedeflerinize uzman danışmanlarımız ile daha kolay ulaşabilirsiniz!</p>
			            
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/emlakresimlerix/emlak32-1.png"} alt="#" />
			          </div>
			          <div className="ltn__feature-info">
			            <h3>Gayrimenkul Kiralayın!</h3>
			            <p>Gayrimenkul kiralama süreçlerinde deneyimli gayrimenkul danışmanlarımız yardımcı olsun!</p>
			            
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/emlakresimlerix/emlak33-1.png"} alt="#" />
			          </div>
			          <div className="ltn__feature-info">
			            <h3>Gayrimenkul Satın!</h3>
			            <p>Emlak Kılavuzu pazarlama planı ile hızlı ve doğru fiyata gayrimenkulünüzü satın!</p>
			            
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default FeaturesV1