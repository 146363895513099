import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { productList } from '../../helper/staticDataList';

class ProductSliderV3 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__search-by-place-area section-bg-1 before-bg-top--- bg-image-top--- pt-115 pb-70" data-bs-bg={publicUrl+"assets/img/bg/20.jpg"}>
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Emlak Kılavuzu</h6>
			          <h1 className="section-title">+Plus Projelerimiz</h1>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__search-by-place-slider-1-active slick-arrow-1 go-top">
			    
				{/* ProductSlide */}
				{productList.map((row, i) => {
					return (
						<div className="col-lg-4">
						<div className="ltn__search-by-place-item">
						  <div className="search-by-place-img">
									<Link to={ `/product-details/${row.id}`} ><img src={row.image} alt="#" /></Link>
							<div className="search-by-place-badge">
							  {/* <ul>
								<li>2 Properties</li>
							  </ul> */}
							</div>
						  </div>
						  <div className="search-by-place-info">
									<h6><Link to="/contact">{row.localAdress	}</Link></h6>
									<h4><Link to={ `/product-details/${row.id}`}>{row.title}</Link></h4>
							<div className="search-by-place-btn">
							  <Link to={ `/product-details/${row.id}`} >Devamını Gör <i className="flaticon-right-arrow" /></Link>
							</div>
						  </div>
						</div>
					  </div>
					)
				})}
	
			      {/* ProductSlide */}
			    </div>
			  </div>
			</div>
        }
}

export default ProductSliderV3