import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Social from '../section-components/social';

class NavbarV3 extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'logo'
		let anchor = '#'
console.log(this.props.scrollPosition);
        return (
		<div>
           <header className="ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent gradient-color-4---">
			  <div className="ltn__header-top-area top-area-color-white">
			    <div className="container">
			      <div className="row">
			        <div className="col-md-7">
			          <div className="ltn__top-bar-menu">
			            <ul>
			              <li><a href="mailto:info@emlakkilavuzu.com?Subject=Tavsiye"><i className="icon-mail" /> info@emlakkilavuzu.com</a></li>
			              
			            </ul>
			          </div>
			        </div>
			        <div className="col-md-5">
			          <div className="top-bar-right text-end">
			            <div className="ltn__top-bar-menu">
			              <ul>
			                <li>
			                  {/* ltn__language-menu */}
			                  {/* <div className="ltn__drop-menu ltn__currency-menu ltn__language-menu">
			                    <ul>
			                      <li><Link to="#" className="dropdown-toggle"><span className="active-currency">English</span></Link>
			                        <ul>
			                          <li><Link to="#">Arabic</Link></li>
			                          <li><Link to="#">Bengali</Link></li>
			                          <li><Link to="#">Chinese</Link></li>
			                          <li><Link to="#">English</Link></li>
			                          <li><Link to="#">French</Link></li>
			                          <li><Link to="#">Hindi</Link></li>
			                        </ul>
			                      </li>
			                    </ul>
			                  </div> */}
			                </li>
			                <li>
							<Social />
			                </li>
			              </ul>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			  <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-black">
			    <div className="container">
			      <div className="row">
			        <div className="col">
			          <div className="site-logo-wrap">
			            <div className="site-logo go-top">
			              <Link to="/"><img src={publicUrl+"assets/img/emlakLogo.png"} alt="Logo" /></Link>
			            </div>
			            <div className="get-support clearfix d-none">
			              <div className="get-support-icon">
			                <i className="icon-call" />
			              </div>
			              <div className="get-support-info">
			                <h6>Get Support</h6>
			                <h4><a href="tel:+123456789">123-456-789-10</a></h4>
			              </div>
			            </div>
			          </div>
			        </div>
			        <div className="col header-menu-column menu-color-white">
			          <div className="header-menu d-none d-xl-block go-top">
			            <nav>
			              <div className="ltn__main-menu ege2">
			                <ul>
													<li className={this.props.scrollPosition<400?"special-link":"menu-icon"}><a  href="#" >Anasayfa</a>
														{/* Dorpdown */}
			                    {/* <ul className="sub-menu menu-pages-img-show">
									<li>
										<Link to="/">Home Style 01</Link>
										<img src={publicUrl+"assets/img/home-demos/home-1.jpg"} alt="#" />
									</li>
									<li>
										<Link to="/home-v2">Home Style 02</Link>
										<img src={publicUrl+"assets/img/home-demos/home-2.jpg"} alt="#" />
									</li>
									<li>
										<Link to="/home-v3">Home Style 03</Link>
										<img src={publicUrl+"assets/img/home-demos/home-3.jpg"} alt="#" />
									</li>
									<li>
									<Link to="/home-v4">Home Style 04</Link>
									<img src={publicUrl+"assets/img/home-demos/home-4.jpg" } alt="#" /> 
									</li>
									<li>
										<Link to="/home-v5">Home Style 05 <span className="menu-item-badge">video</span></Link>
										<img src={publicUrl+"assets/img/home-demos/home-5.jpg" } alt="#" /> 
									</li>
									<li>
										<Link to="/home-v6">Home Style 06</Link>
										<img src={publicUrl+"assets/img/home-demos/home-6.jpg" } alt="#" /> 
									</li>
									<li>
										<Link to="/home-v7">Home Style 07</Link>
										<img src={publicUrl+"assets/img/home-demos/home-7.jpg" } alt="#" /> 
									</li>
									<li>
										<Link to="/home-v8">Home Style 08</Link>
										<img src={publicUrl+"assets/img/home-demos/home-8.jpg" } alt="#" /> 
									</li>
									<li>
										<Link to="/home-v9">Home Style 09</Link>
										<img src={publicUrl+"assets/img/home-demos/home-9.jpg" } alt="#" /> 
									</li>
									<li>
										<Link to="/home-v10">Home Style 10</Link>
										<img src={publicUrl+"assets/img/home-demos/home-11.jpg"}  alt="#" />
									</li>
			                    </ul> */}
			                  </li>
			                  <li className={this.props.scrollPosition<3700 && this.props.scrollPosition>400 ?"special-link":"menu-icon"} ><Link to="/about">Hakkımızda</Link>
			          
			                  </li>
			                  {/* <li className="menu-icon"><Link to="/shop">Shop</Link>
			                    <ul>
									<li><Link to="/shop">Shop</Link></li>
									<li><Link to="/shop-grid">Shop Grid</Link></li>
									<li><Link to="/shop-left-sidebar">Shop Left sidebar</Link></li>
									<li><Link to="/shop-right-sidebar">Shop Right sidebar</Link></li>
									<li><Link to="/product-details">Shop Details</Link></li>
									<li><Link to="/cart">Cart</Link></li>
									<li><Link to="/checkout">Checkout</Link></li>
									<li><Link to="/my-account">My Account</Link></li>
									<li><Link to="/login">Sign in</Link></li>
									<li><Link to="/register">Register</Link></li>
			                    </ul>
			                  </li> */}
							  <li className={this.props.scrollPosition>3700 && this.props.scrollPosition<4500  ?"special-link":"menu-icon"}><Link to="/blog">Blog</Link>
			               
			                  </li>
							  {}
			                  
			                  <li className="menu-icon">
			                    <Link to="/portfolio-v2">Etkinliklerimiz</Link>
													</li>
													<li className="/team">
			                    <Link to="/team">Ekibimiz</Link>
			                  </li>
							  <li className={this.props.scrollPosition>4500  ?"special-link":"menu-icon"}><Link to="/contact">İletişim</Link></li>
			                </ul>
			              </div>
			            </nav>
			          </div>
			        </div>
			        <div className="col--- ltn__header-options ltn__header-options-2 ">
			          {/* Mobile Menu Button */}
			          <div className="mobile-menu-toggle d-xl-none">
			            <a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle">
			              <svg viewBox="0 0 800 600">
			                <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top" />
			                <path d="M300,320 L540,320" id="middle" />
			                <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) " />
			              </svg>
			            </a>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</header>
			<div id="ltn__utilize-mobile-menu" className="ltn__utilize ltn__utilize-mobile-menu">
				<div className="ltn__utilize-menu-inner ltn__scrollbar">
					<div className="ltn__utilize-menu-head">
					<div className="site-logo">
						<Link to="/"><img src={publicUrl+"assets/img/emlakLogo.png"} alt="Logo" /></Link>
					</div>
					<button className="ltn__utilize-close">×</button>
					</div>

					<div className="ltn__utilize-menu">
					<ul>
						<li><a href="#">Anasayfa</a>
						</li>
						<li><Link to="/about">Hakkımızda</Link>
						</li>
				
						<li><Link to="/blog">Blog</Link></li>

								<li><Link to="/portfolio-v2">Etkinliklerimiz</Link></li>
								<li><Link to="/team">Ekibimiz</Link></li>

						<li><Link to="/contact">Iletisim</Link></li>
					</ul>
					</div>
					<div className="ltn__utilize-buttons ltn__utilize-buttons-2">
					
					</div>
					<div className="ltn__social-media-2">
					<ul>
					<li><a href="https://www.facebook.com/emlakkilavuzu/?hc_ref=ARSl3nI4vn6XcaHYc4xl6P0Rfg1vFO_kQOMa_BikB26Ico1U3_55sOrrVzpl_LCn674&fref=nf&__xts__[0]=68.ARA5H46bYpV6ux2gh4NWiNgfgZ1uRJNRF5qnSMBAZSLwlCO79Yo7hjby5wMdCK5Pkvn48v05pTnKlrMSz8Aka2A4mXQGzArU0SJrlldL1fPFIk8_8NycVh5ab-vptv5OvoyPuW2kGR2B0q_XF-g6xrVY4NxFoCVEdB2O-0Vy7ONCdOaxt_Ur4oBVoJseM-xiXkxuxILgjcMVpylYCboJ1uoeJRrfLvEXX3n7NiVEPnVFxYZAZLpPdOkIXAVMOkRef6gmXGIyF_VUeLtVrKe3iz84zhIrZPBWR7fZTT73_vsLDSv6fVM" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
				
				<li><a href="https://www.instagram.com/emlakkilavuzu/" title="Instagram"><i className="fab fa-instagram" /></a></li>
				<li><a href="https://emlakkilavuzu.sahibinden.com/" title="Dribbble"><i className="fab fa-dribbble" /></a></li>
				<li><a href="https://www.zingat.com/emlak-kilavuzu-59042g" title="Dribbble"><img src={publicUrl+"assets/zingat.png"} color='white' width={20	} height={19}></img></a></li>
				<li><a href="https://www.linkedin.com/company/emlak-kilavuzu/?originalSubdomain=tr" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
				<li><a href="https://www.youtube.com/@EmlakKilavuzu" title="Youtube"><i className="fab fa-youtube" /></a></li>
						
						
					</ul>
					</div>
				</div>
				</div>
		</div>
        )
    }
}


export default NavbarV3