import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { articleList } from '../../helper/staticDataList';
import Sidebar from './sidebar';

class BlogSlider extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL+'/'
    let customClass = this.props.customClass ? this.props.customClass :''
    let sectionClass = this.props.sectionClass ? this.props.sectionClass :''
    return (
      <div className={ "ltn__blog-area pt-115--- pb-70 go-top "+ sectionClass}>
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-12">
		        <div className="section-title-area ltn__section-title-2--- text-center">
				<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Emlak Kılavuzu</h6>
		          <h1 className="section-title">Haberler ve Makaleler</h1>
		        </div>
		      </div>
		    </div>
		    <div className="row  ltn__blog-slider-one-active slick-arrow-1 ltn__blog-item-3-normal">
					{/* Blog Item */}
					{
						articleList.map((row) => {
							return (
								<div className="col-lg-12">
								<div className="ltn__blog-item ltn__blog-item-3">
								  <div className="ltn__blog-img">
									<Link to={`/blog-details/${row.id}`}><img src={row.image} alt="#" /></Link>
								  </div>
								  <div className="ltn__blog-brief">
									<div className="ltn__blog-meta">
									  <ul>
										<li className="ltn__blog-author">
										  <Link to="/team-details"><i className="far fa-user" />Yazar: {row.writerBy} </Link>
										</li>
													<li className="ltn__blog-tags">
														{row.tag?.map((row) => {
															return (
																<Link to={`/blog-details/${row.id}`}><i className="fas fa-tags"  />{row}</Link>
															)
														})}
										  
								
										</li>
									  </ul>
									</div>
									<h3 className="ltn__blog-title"><Link to={`/blog-details/${row.id}`}>{row.title}</Link></h3>
									<div className="ltn__blog-meta-btn">
									  <div className="ltn__blog-meta">
										<ul>
														<li className="ltn__blog-date"><i className="far fa-calendar-alt" />{row.date}</li>
										</ul>
									  </div>
									  <div className="ltn__blog-btn">
										<Link to={`/blog-details/${row.id}`}>Devamını gör</Link>
									  </div>
									</div>
								  </div>
								</div>
							  </div>
							)
						})	
					}
	
	
		      {/*  */}
		    </div>
		  </div>
		</div>
    )
  }
}

export default BlogSlider;
