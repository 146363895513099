import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV4 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
				<div className="container">
				<div className="row">
					<div className="col-lg-6 align-self-center">
					<div className="about-us-img-wrap about-img-left">
						<img src={publicUrl+"assets/img/others/aboutresim.jpg"} alt="About Us Image" />
						<div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
						{/* <div className="ltn__video-img ltn__animation-pulse1">
							<img src={publicUrl+"assets/img/others/8.png"} alt="video popup bg image" />
							<a className="ltn__video-icon-2 ltn__video-icon-2-border---" href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0" data-rel="lightcase:myCollection">
							<i className="fa fa-play" />
							</a>
						</div> */}
						</div>
					</div>
					</div>
					<div className="col-lg-6 align-self-center">
					<div className="about-us-info-wrap">
						<div className="section-title-area ltn__section-title-2--- mb-20">
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Hakkımızda</h6>
						<h1 className="section-title">Emlak Kılavuzu
							</h1>
						<p>
Yatırımlarınıza kılavuzluk ediyoruz... 
2019 yılında bir Youtube Kanalı olarak kurulan EMLAK KILAVUZU, gayrimenkul sektöründe 7 yılı aşkın zamandır deneyime sahip, sektörde yeniliklerin öncülüğünü ilke edinmiş, deneyimli ekibi ve teknolojik alt yapısı ile müşterilerine hizmet vermeye devam etmektedir. 
<br></br><br></br>Emin adımlarla ilerleyen <b>EMLAK KILAVUZU, SEMTİNİZİN EMLAK REHBERİ</b> sloganıyla, gayrimenkul sektöründe kaliteli ve güvenilir hizmet vermek için yanı başınızda olacaktır.</p>
						</div>
						
						
						<div className="btn-wrapper animated">
						
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>
        }
}

export default AboutV4